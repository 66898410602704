/* global isJestTest, SITE_THEME */
import React, { useContext } from 'react';
import BaseContext from 'Layouts/BasePage/BaseContext';

export const useStyle = ({
    Medborgarskolan = null,
    Kulturama = null,
    Pahlmans = null,
    Tillskararakademin = null,
    Dansskolanentre = null,
}, defaultTheme = '') => {
    const theme = defaultTheme || useTheme();

    // Key must be the theme name defined in backend / nextjs env
    const themes = {
        Medborgarskolan: Medborgarskolan,
        Kulturama: Kulturama,
        Pahlmans: Pahlmans,
        PahlmansGy: Pahlmans,
        Tillskararakademin: Tillskararakademin,
        Dansskolanentre: Dansskolanentre,
    };

    if(!(theme in themes)) {
        throw new Error(`Theme ${theme} doesn't exist`);
    }

    if(!themes[theme]) {
        return {};
    } else {
        return themes[theme];
    }
};

export const useTheme = () => {
    const { currentTheme } = useContext(BaseContext);

    // Make sure a theme is used even for tests
    const isTest = typeof isJestTest !== 'undefined' && isJestTest;
    const siteTheme = typeof SITE_THEME !== 'undefined' && SITE_THEME;
    const testTheme = currentTheme || siteTheme || 'Medborgarskolan';
    const theme = isTest ? testTheme : currentTheme;
    return theme;
};
