import {createContext} from 'react';

const BaseContext = createContext({
    currentTheme: '',
    cookiePopup: {},
    isSSR: false,
    isPreview: false,
    language: '',
    containerName: '',
});

export default BaseContext;