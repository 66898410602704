/**
 * ErrorBoundary
 */

import React from 'react';
// import PropTypes from 'prop-types';
import s from './ErrorBoundary.Base.module.scss';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }
    static getDerivedStateFromError(error) {
        return { hasError: true }
    }
    componentDidCatch(error, errorInfo) {
        console.log({ error, errorInfo });
    }
    render() {
        if (this.state.hasError) {
            return (
                <div className={s['Root']}>
                    <h2>Hoppsan, något gick fel!</h2>
                    <button
                        type="button"
                        onClick={() => this.setState({ hasError: false })}
                    >
                        Försök igen?
                    </button>
                </div>
            )
        };
        return this.props.children;
    }
}

export default ErrorBoundary;