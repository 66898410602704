import localFont from 'next/font/local';
import {
    Barlow,
    Barlow_Condensed,
    Cormorant_Garamond,
    Lexend_Deca,
    Nunito,
    Zilla_Slab,
} from 'next/font/google';

/**
 * Define all fonts and export in each theme
 * If font-heading will fallback to font-base if it isn't needed
 */

const lexendDeca = Lexend_Deca({
    variable: '--font-base',
    weight: ['300', '400', '500', '700'],
    subsets: ['latin'],
    fallback: ['Arial', 'sans-serif'],
});

export const Dansskolanentre = [lexendDeca.variable];

const din = localFont({
    variable: '--font-base',
    fallback: ['Arial', 'sans-serif'],
    src: [
        {
            path: '../public/fonts/din/6ceed230-b2b3-4422-b048-4aa11687430a.woff2',
            weight: '400',
            style: 'normal',
        },
        {
            path: '../public/fonts/din/398e3e8c-3bf0-4af1-9791-f426a7992711.woff2',
            weight: '500',
            style: 'normal',
        },
        {
            path: '../public/fonts/din/55fa1cf8-baf8-4bf8-9718-b096b1dd231f.woff2',
            weight: '700',
            style: 'normal',
        },
    ],
});

export const Kulturama = [din.variable];

const barlow = Barlow({
    variable: '--font-base',
    weight: ['400', '500', '700'],
    subsets: ['latin'],
    fallback: ['Arial', 'sans-serif'],
});

const barlowCondensed = Barlow_Condensed({
    variable: '--font-heading',
    weight: ['400', '500', '700'],
    subsets: ['latin'],
    fallback: ['Tahoma', 'Arial', 'sans-serif'],
});

export const Medborgarskolan = [barlow.variable, barlowCondensed.variable];

const nunito = Nunito({
    variable: '--font-base',
    weight: ['400', '500', '600', '700', '900'],
    subsets: ['latin'],
    fallback: ['Arial', 'sans-serif'],
});

const zillaSlab = Zilla_Slab({
    variable: '--font-heading',
    weight: ['400', '500', '600', '700'],
    subsets: ['latin'],
    fallback: ['Georgia', 'serif'],
});

export const Pahlmans = [nunito.variable, zillaSlab.variable];

const cormorantGaramond = Cormorant_Garamond({
    variable: '--font-heading',
    weight: ['400', '600', '700'],
    subsets: ['latin'],
    fallback: ['Palatino Linotype', 'Book Antiqua', 'Palatino', 'serif'],
});

export const Tillskararakademin = [nunito.variable, cormorantGaramond.variable];
